/*** Font Family ***/
@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaBlack.eot');
	src: url('./fonts/SVN-ProximaNovaBlack.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaBlack.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaBlack.woff') format('woff'), url('./fonts/SVN-ProximaNovaBlack.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaBlack.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaBlackItalic.eot');
	src: url('./fonts/SVN-ProximaNovaBlackItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaBlackItalic.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaBlackItalic.woff') format('woff'), url('./fonts/SVN-ProximaNovaBlackItalic.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaBlackItalic.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaBold.eot');
	src: url('./fonts/SVN-ProximaNovaBold.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaBold.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaBold.woff') format('woff'), url('./fonts/SVN-ProximaNovaBold.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaBold.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaBoldItalic.eot');
	src: url('./fonts/SVN-ProximaNovaBoldItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaBoldItalic.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaBoldItalic.woff') format('woff'), url('./fonts/SVN-ProximaNovaBoldItalic.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaBoldItalic.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaExtraBold.eot');
	src: url('./fonts/SVN-ProximaNovaExtraBold.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaExtraBold.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaExtraBold.woff') format('woff'), url('./fonts/SVN-ProximaNovaExtraBold.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaExtraBold.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaExtraBoldItalic.eot');
	src: url('./fonts/SVN-ProximaNovaExtraBoldItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaExtraBoldItalic.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaExtraBoldItalic.woff') format('woff'), url('./fonts/SVN-ProximaNovaExtraBoldItalic.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaExtraBoldItalic.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaItalic.eot');
	src: url('./fonts/SVN-ProximaNovaItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaItalic.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaItalic.woff') format('woff'), url('./fonts/SVN-ProximaNovaItalic.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaItalic.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaLight.eot');
	src: url('./fonts/SVN-ProximaNovaLight.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaLight.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaLight.woff') format('woff'), url('./fonts/SVN-ProximaNovaLight.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaLight.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaLightItalic.eot');
	src: url('./fonts/SVN-ProximaNovaLightItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaLightItalic.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaLightItalic.woff') format('woff'), url('./fonts/SVN-ProximaNovaLightItalic.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaLightItalic.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaRegular.eot');
	src: url('./fonts/SVN-ProximaNovaRegular.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaRegular.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaRegular.woff') format('woff'), url('./fonts/SVN-ProximaNovaRegular.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaRegular.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaSemiBold.eot');
	src: url('./fonts/SVN-ProximaNovaSemiBold.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaSemiBold.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaSemiBold.woff') format('woff'), url('./fonts/SVN-ProximaNovaSemiBold.ttf') format('truetype'), url('./fonts/SVN-ProximaNovaSemiBold.svg#svgFontName') format('svg');
	font-display: auto;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'SVN-Proxima Nova';
	src: url('./fonts/SVN-ProximaNovaSemiBoldItalic.eot');
	src: url('./fonts/SVN-ProximaNovaSemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('./fonts/SVN-ProximaNovaSemiBoldItalic.woff2') format('woff2'), url('./fonts/SVN-ProximaNovaSemiBoldItalic.ttf') format('truetype');
	font-display: auto;
	font-style: italic;
	font-weight: 600;
}

/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVLSTAyLFyeg_IDWvOJmVES_HwyPRsibZgmWBygaXxN.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVLSTAyLFyeg_IDWvOJmVES_HwyPRsibJgmWBygaXxN.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVLSTAyLFyeg_IDWvOJmVES_HwyPRsiYpgmWBygaQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPbczdbADdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPbczdbEDdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPbczdb8DdzSqYUQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPdMwdbADdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPdMwdbEDdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPdMwdb8DdzSqYUQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVNSTAyLFyeg_IDWvOJmVES_HwyNXcSYJIheh6QaA.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVNSTAyLFyeg_IDWvOJmVES_HwyNXYSYJIheh6QaA.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVNSTAyLFyeg_IDWvOJmVES_HwyNXgSYJIheh4.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPYsxdbADdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPYsxdbEDdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPYsxdb8DdzSqYUQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPac2dbADdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPac2dbEDdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPac2db8DdzSqYUQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPcM3dbADdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPcM3dbEDdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPcM3db8DdzSqYUQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPd80dbADdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPd80dbEDdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPd80db8DdzSqYUQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPfs1dbADdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPfs1dbEDdzSqYUR0jg.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPfs1db8DdzSqYUQ.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HT4JG86Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HT4JG87Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HT4JG81Rb0JcBao.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HScJ286Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HScJ287Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HScJ281Rb0JcBao.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVPSTAyLFyeg_IDWvOJmVES_Hw4BXoYZ7AjSh8.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVPSTAyLFyeg_IDWvOJmVES_Hw5BXoYZ7AjSh8.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVPSTAyLFyeg_IDWvOJmVES_Hw3BXoYZ7Aj.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HTEJm86Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HTEJm87Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HTEJm81Rb0JcBao.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HToIW86Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HToIW87Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HToIW81Rb0JcBao.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSMIG86Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSMIG87Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSMIG81Rb0JcBao.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSQI286Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSQI287Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSQI281Rb0JcBao.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HS0Im86Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HS0Im87Rb0JcBaoUUU.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HS0Im81Rb0JcBao.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.dashboard-tbl-new-orders th,
.dashboard-tbl-new-users th {
  background-color: white !important;
  font-size: 12px !important;
  font-weight: normal !important;
  color: #818898 !important;
  vertical-align: top;
  font-family: SVN-Proxima Nova !important;
  font-style: normal;
  line-height: 16px !important;
  padding: 16px 20px;
}

.btn-active-data {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(1, 18, 34, 0.08);
}

footer {
  color: #5c6370;
  box-shadow: 0px -4px 4px rgba(1, 18, 34, 0.08);
}

/* RC Table */
.rc-table {
  @apply border-separate shadow-sm;
  border-spacing: 0;
}

.rc-table .rc-table-content,
.rc-table .rc-table-body,
.rc-table .rc-table-header {
  @apply border-0;
}

.rc-table tr th {
  @apply border-0 border-b border-border-200;
}

/* .rc-table tr td {
  @apply border-0;
} */

.rc-table thead.rc-table-thead th {
  @apply bg-gray-100 py-5 px-4 font-body font-bold text-sm text-heading border-border-200;
}

.rc-table tr.rc-table-row:hover {
  background-color: inherit;
}

.rc-table tr.rc-table-row td {
  @apply py-5 px-2 font-body text-base font-normal text-heading;
}

.rc-table tr.rc-table-row:not(.rc-table-expanded-row) {
  cursor: pointer;
}

.rc-table tr.rc-table-expanded-row > td {
  @apply p-0 whitespace-normal border border-gray-50;
}

.rc-table tr.rc-table-expanded-row tr.rc-table-row {
  cursor: default;
}

.rc-table .rc-table-expanded-row-fixed::after {
  border-width: 0;
}

.rc-table.strip tr.rc-table-row:nth-child(2n + 1) td {
  @apply bg-gray-1100;
}

.products-table .rc-table-content {
	overflow: unset!important;

	@media (max-width: 640px) {
		overflow: auto visible!important;
	}
}

.products-table thead.rc-table-thead th {
  @apply bg-light bg-gray-1100 text-gray-1300 font-semibold border text-sm font-medium py-4 px-3;
}

.products-table tr.rc-table-row td {
  @apply text-sm text-black cursor-default border px-2 py-[18px] h-[64px];
}

.products-table tr.rc-table-row td input {
  @apply px-2 py-1 text-sm text-black h-[28px] bg-transparent;
}

.products-table tr.rc-table-row td a {
  @apply text-accent cursor-pointer;
}

.products-table tr.rc-table-row td:first-child {
	padding: 0;
	text-align: center!important;
}

.custom-modal {
  overflow: auto !important;
}

.custom-modal .btn-off {
  border: 1px solid #ff1d30;
  background-color: white;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ff1d30;
  width: 140px;
  height: 48px;
}

.btn-bg-white {
  border: 1px solid #6aa543;
  background-color: white;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6aa543;
  height: 48px;
}

.btn-bg-blue {
  background-color: #243E85;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: white;
  height: 48px;
}

.btn-bg-white:hover,
.custom-modal .btn-off:hover {
  background-color: #019376;
  border-color: #019376;
  color: white;
}

.custom-modal .btn-save-and-close {
  border: 1px solid #6aa543;
  background-color: white !important;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6aa543;
}

.custom-modal .custom-input-date input {
  height: 56px !important;
}

.padding-none {
  padding: 0 !important;
}

.date-picker-icon {
	background-image: url('./img/calendar-icon.svg');
	background-repeat: no-repeat;
	background-size: 20px;
	background-position: 95% 50%;
	font-weight: 600;
}

.date-picker-icon {
	background-image: url('./img/calendar-icon.svg');
	background-repeat: no-repeat;
	background-size: 20px;
	background-position: 95% 50%;
}

.input-dph {
	position: relative;
}

.input-dph::after {
	content: 'Kč';
	display: block;
	font-weight: 600;
	line-height: 20px;
	position: absolute;
	font-size: 14px;
	right: 20px;
	top: 42px;
}

.input-dph-top::after {
	top: 14px;
}

.input-dph-percent::after {
	content: '%';
}

.product-form-submit {
	bottom: 0px;
}

.product-options li:last-child {
	border-bottom: 0px!important;
}

.attributes-table thead.rc-table-thead th {
  @apply bg-light bg-gray-1800 text-black font-semibold text-sm font-semibold py-4 px-3;
}

.attributes-table tr.rc-table-row td {
  @apply first:p-0 text-sm text-gray-1300 cursor-default py-4;
}

.svg-color:hover svg path {
	stroke: #ffffff;
}

.categories-search-input {
	height: 40px;
}

.attribute-form input {
	height: 54px;
}

.bg-green-important {
	background-color: #6AA543 !important;
}

.text-green-important {
	color: #6AA543 !important;
}

.bg-green-industry {
	background: rgba(106, 165, 67, 0.1);
}

.shadow-footer {
	box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.05);
}

.shop-rating {
	background: rgba(255, 168, 41, 0.05);
	border: 1px solid rgba(255, 168, 41, 0.5);
	box-sizing: border-box;
	border-radius: 5px;
}

.Toastify__toast-body {
	white-space: pre-line;
}

.input-tax {
	position: relative;
}

.input-tax::after {
	content: '%';
	display: block;
	font-weight: 600;
	line-height: 20px;
	position: absolute;
	font-size: 14px;
	right: 20px;
	top: 15%;
}

.input-tax:hover::after,
.input-tax:focus-within::after {
  	right: 40px;
}

.input-new-tax::after {
	top: 28%;
}

.input-update-tax {
	height: 32px;
}

.input-kc {
	position: relative;
}

.input-kc::after {
	content: 'Kč';
	display: block;
	font-weight: 600;
	line-height: 20px;
	position: absolute;
	font-size: 14px;
	right: 20px;
	top: 45px;
}

.input-kc-hover:hover::after,
.input-kc-hover:focus-within::after {
  right: 40px;
}

.rc-table-row-expand-icon {
  display: none !important;
}

.height-50px {
  height: 50px !important;
}

.custom-input-time {
  border: none !important;
  caret-color: transparent;
}

.input-bez-dph,
.input-s-dph {
  position: relative;
}

.input-bez-dph::after {
  content: 'bez DPH';
  display: block;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
  font-size: 14px;
  right: 10px;
  top: 45px;
}

.input-s-dph::after {
  content: 's DPH';
  display: block;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
  font-size: 14px;
  right: 10px;
  top: 45px;
}

.fill-accent-800 svg path {
  fill: rgb(var(--color-accent-800));
}

.order-table tr.rc-table-row td:first-child {
	padding: 0 16px;
	text-align: left!important;
}

.order-product-detail thead.rc-table-thead th  {
	@apply bg-light border-0 border-b border-gray-1100 text-gray-1300 font-normal text-xs py-3 px-2;
}

.order-product-detail tr.rc-table-row td {
	@apply border-0 py-4 first:pl-0 text-sm text-black align-top cursor-default;
}

.quantity-group {
	position: relative;
}

.quantity-group.show-tooltip:before {
	content: attr(title);
	position: absolute;
	bottom: 60%;
	text-indent: 0;
	background: #FF3D43;
	padding: 5px 10px;
	line-height: 1;
	border-radius: 4px;
	margin-bottom: 10px;
	min-width: 50px;
	color: #fff;
	left: 97%;
	white-space: pre;
	text-align: center;
	text-transform: none;
	transform: translate(-50%, 0);
	-webkit-transform: translate(-50%, 0);
	transition: all 250ms ease-out;
}

.quantity-group.show-tooltip:after {
	content: "";
	position: absolute;
	left: 50%;
	bottom: 60%;
	border-width: 4px 4px 0 4px;
	border-style: solid;
	border-color: #FF3D43 transparent transparent transparent;
	margin-bottom: 6px;
	margin-left: -4px;
	transition: all 250ms ease-out;
}

.extend-orders .slick-prev {
	left: -5px;
	z-index: 30;
}

.extend-orders .slick-prev.slick-disabled,
.extend-orders .slick-next.slick-disabled {
	display: none!important;
}

.extend-orders .slick-next {
	right: -5px;
	z-index: 30;
}

.extend-orders .slick-track {
	margin: unset;
}

.product-list-input.quantity-group.show-tooltip:before {
	bottom: 90%;
	left: 10%;
}

.quantity-group.show-tooltip:after {
	bottom: 90%;
}

.popup-error-order thead.rc-table-thead th  {
	@apply bg-light border-0 border-b border-gray-1100 text-gray-1000 font-semibold text-sm py-4 px-2;
}

.popup-error-order tr.rc-table-row td {
	@apply border-0 py-4 text-sm text-black align-top;
}

.text-align-webkit-center {
  text-align: -webkit-center;
}

.claim-table .rc-table-content {
	overflow: unset!important;

	@media (max-width: 640px) {
		overflow: auto!important;
	}
}

.claim-table thead.rc-table-thead th {
  @apply bg-light bg-gray-1100 text-gray-1300 font-semibold border text-xs font-normal py-4;
}

.claim-table tr.rc-table-row td {
  @apply text-sm text-black cursor-default border py-4;
}

.toggle-top-claim-order-select {
	bottom: 35px;
	margin: unset;
}

.claim-input-dph {
	position: relative;
}

.claim-input-dph::after {
	content: 'Kč';
	display: block;
	font-weight: 600;
	line-height: 20px;
	position: absolute;
	font-size: 14px;
	right: 20px;
	top: 16px;
}

.button-claim {
	font-size: 16px;
}

.claim-input-kc {
	position: relative;
}

.claim-input-kc::after {
	content: 'Kč';
	display: block;
	font-weight: 600;
	line-height: 20px;
	position: absolute;
	font-size: 14px;
	right: 20px;
	top: 45px;
}

.claim-input-kc-hover:hover::after,
.claim-input-kc-hover:focus-within::after {
  right: 40px;
}

.claim-input-kc label {
	color: rgba(var(--color-black), var(--tw-text-opacity));
}

.text-ellipsis {
	text-overflow: ellipsis;
}

.phone-number {
	position: relative;
}

.phone-number::before {
	content: '+420';
	display: block;
	font-weight: 600;
	line-height: 20px;
	position: absolute;
	font-size: 14px;
	left: 17px;
	top: 14px;
}

.phone-number input {
	padding-left: 55px;
	padding-right: 42px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.company-logo {
	border: 2px solid #FAFAFA!important;
}

.label-sale {
	color: red;
}

.hover-change-color-class:hover {
	color: rgba(var(--color-accent), var(--tw-text-opacity));
}

.hover-change-color-class:hover svg path {
	stroke: rgba(var(--color-accent), var(--tw-text-opacity));
}

.linkable-order-tree .rst__collapseButton {
	display: none;
}

.linkable-order-tree .rst__lineHalfHorizontalRight:first-child {
	display: none;
}

.linkable-order-tree .rst__node {
	height: 76px!important;
}

.linkable-order-tree .rst__rowWrapper {
	padding-right: 0;
}

.linkable-order-tree .rst__rowContents {
	height: 64px;
	border-radius: 8px;
	border: 1px solid #EFEFF1;
	background: #FFFFFF;
	box-shadow: none;
	box-sizing: border-box;
}

.linkable-order-tree .rst__rowContents:has(.tree-data-owner-class) {
	border: 1px solid #6AA543;
	background: #F0F6EC;
}

.linkable-order-tree .rst__node .rst__lineBlock:first-child {
	width: 0!important;
}

.linkable-order-tree .rst__nodeContent {
	left: 44px!important;
	width: calc(100% - 44px);
}

.linkable-order-tree .rst__node:first-child .rst__nodeContent {
	left: 0!important;
	width: 100%;
}

.linkable-order-tree .rst__lineHalfHorizontalRight::before,
.linkable-order-tree .rst__lineFullVertical::after,
.linkable-order-tree .rst__lineHalfVerticalTop::after,
.linkable-order-tree .rst__lineHalfVerticalBottom::after,
.linkable-order-tree .rst__lineChildren::after {
	background-color: #EFEFF1;
}

.shop-list-search-input {
	height: 40px;
	color: #17181C;
	font-weight: 400;
	padding: 0 48px;
	caret-color: #6AA543;
	border-radius: 8px;
	background-color: #EDEDED;
}

.shop-list-search-input:focus {
	border-color: #6AA543;
	background-color: #EFEFF1;
}

.shop-list-search-input::placeholder {
	color: #5C6370;
	font-size: 14px;
	font-weight: 400;
}

.backgroud-accent {
	@apply bg-accent-800
}

.zero-quantity {
	color: #D1D0D9
}

.edit-button-home-banner {
	height: 40px;
}

.edit-button-home-banner:hover svg path {
	fill: #ffffff;
}

::-webkit-input-placeholder { /* Edge */
	font-weight: 400;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-weight: 400;
}

::placeholder {
	font-weight: 400;
}

.button-delete-order:hover svg path {
	stroke: #fff;
}

.promotion-name {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	min-height: 40px;
	font-size: 16px;
	margin-bottom: 12px;
}

.exprired-promotion {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.gift-name {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	min-height: 48px;
	font-size: 16px;
	margin-bottom: 8px;
	line-height: 24px;
}

.shop-select-type-search-input {
	height: 40px;
	color: #17181C;
	font-weight: 400;
	padding: 0 48px;
	caret-color: #6AA543;
	border-radius: 8px;
	background-color: #EDEDED;
}

.shop-select-type-search-input:focus {
	border-color: #6AA543;
	background-color: #EFEFF1;
}

.shop-select-type-search-input::placeholder {
	color: #5C6370;
	font-size: 14px;
	font-weight: 400;
}

.image-upload-gift {
	position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.image-upload-gift-wrapper::before {
	content: '';
	position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
	z-index: 100;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
	border-radius: 10px;
}

.be-viet-pro,
.be-viet-pro button,
.be-viet-pro input,
.be-viet-pro optgroup,
.be-viet-pro select,
.be-viet-pro textarea,
.be-viet-pro h1,
.be-viet-pro h2,
.be-viet-pro h3,
.be-viet-pro h4,
.be-viet-pro h5,
.be-viet-pro h6 {
    font-family: 'Be Vietnam Pro'!important;
}

.keyword-table th,
.keyword-table td {
	font-family: 'Be Vietnam Pro'!important;
}

.delivery-item:hover .clear-date-icon {
	display: block;
}

.clear-date-icon {
	display: none;
}

.grow-0 {
	flex-grow: 0;
}

.shrink-0 {
	flex-shrink: 0;
}

.grow {
	flex-grow: 1;
}

.customer-search-input {
	min-width: 343px;
	height: 40px;
	color: #17181C;
	font-weight: 400;
	padding: 0 48px;
	caret-color: #6AA543;
	border-radius: 8px;
	background-color: #EDEDED;
}

.warning-inactive-shop {
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}

.warning-inactive-image-shop {
	display: block;
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;
	opacity: 0.6;
	background-color: #fff;
}

.history-outofstock {
	border-radius: 222px;
	background: rgba(23, 24, 28, 0.60);
	display: flex;
	padding: 4px;
	align-items: center;
	gap: 4px;
	color: var(--neutral-white, #FFF);
	font-family: Be Vietnam Pro;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	position: absolute;
	top: 50%;
	transform: translate(10%, -50%);
}

.thumb-image-overlay {
	position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 8px;
}

.warning-shop .size-image {
	color: #5C6370;
}
.warning-shop .conver , .warning-shop .avatar, .warning-shop .size-image, .warning-shop .vertical-line {
	font-size: 12px;
	font-weight: 500;
}
