@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*
    *
    * This all are the rgb values which are used inside the
    * tailwind config file and custom plugins css file
    *
    */

    /*
    * primary color
    */
    --color-accent: 0, 159, 127;

    /*
    * Hover state color of primary color
    */
    --color-accent-hover: 1, 147, 118;

    /*
    * shade of primary color
    */
    --color-accent-300: 153, 217, 204;

    /*
    * shade of primary color
    */
    --color-accent-400: 77, 188, 165;

    /*
    * shade of primary color
    */
    --color-accent-500: 0, 159, 127;

    /*
    * shade of primary color
    */
    --color-accent-600: 0, 143, 114;

    /*
    * shade of primary color
    */
    --color-accent-700: 0, 119, 95;

    /*
    * shade of primary color
    */
    --color-accent-800: 106, 165, 67;

    /*
    * shade of primary color
    */
    --color-accent-900: 196, 216, 183;

    /*
    * shade of primary color
    */
    --color-accent-1000: 217, 233, 208;

    /*
    * light color
    */
    --color-light: 255, 255, 255;

    /*
    * dark color
    */
    --color-dark: 0, 0, 0;

    /*
    * black color
    */
    --color-black: 23, 24, 28;

    /*
    * Black muted color
    */
    --color-muted-black: 17, 17, 17;

    /*
    * Black color
    */
    --color-black-1000: 23, 24, 28;
    /*
    *
    * TEXT colors
    *
    */

    /*
    * text color used for body/base text -> gray-500
    */
    --text-base: 107, 114, 128;

    /*
    * dark base text color  -> gray-600
    */
    --text-base-dark: 75, 85, 99;

    /*
    * muted text color -> gray-400
    */
    --text-muted: 156, 163, 175;

    /*
    * light muted text color - gray-300
    */
    --text-muted-light: 209, 213, 219;

    /*
    * text color used for heading text -> gray-700
    */
    --text-sub-heading: 55, 65, 81;

    /*
    * text color used for heading text -> gray-800
    */
    --text-heading: 31, 41, 55;

    /*
    * text color used for heading text -> gray-900
    */
    --text-bolder: 31, 41, 55;

    /*
    *
    * border colors
    *
    */

    /*
    * border color 50
    */
    --color-border-50: 249, 250, 251;

    /*
    * border color 100
    */
    --color-border-100: 243, 244, 246;

    /*
    * border color 200
    */
    --color-border-200: 229, 231, 235;

    /*
    * border base color which is 300
    */
    --color-border-base: 209, 213, 219;

    /*
    * border color 400
    */
    --color-border-400: 156, 163, 175;

    /*
    *
    * Tailwind default gray color override
    *
    */

    /*
    * gray-50
    */
    --color-gray-50: 249, 250, 251;

    /*
    * gray-100
    */
    --color-gray-100: 243, 244, 246;

    /*
    * gray-200
    */
    --color-gray-200: 229, 231, 235;

    /*
    * gray-300
    */
    --color-gray-300: 209, 213, 219;

    /*
    * gray-400
    */
    --color-gray-400: 156, 163, 175;

    /*
    * gray-500
    */
    --color-gray-500: 107, 114, 128;

    /*
    * gray-600
    */
    --color-gray-600: 75, 85, 99;

    /*
    * gray-700
    */
    --color-gray-700: 55, 65, 81;

    /*
    * gray-800
    */
    --color-gray-800: 31, 41, 55;

    /*
    * gray-900
    */
    --color-gray-900: 31, 41, 55;

    /*
    * gray-1000
    */
    --color-gray-1000: 129, 136, 152;

    /*
    * gray-1100
    */
    --color-gray-1100: 241, 241, 244;

    /*
    * gray-1200
    */
    --color-gray-1200: 213, 215, 221;

    /*
    * gray-1300
    */
    --color-gray-1300: 92, 99, 112;

    /*
    * gray-1400
    */
    --color-gray-1400: 122, 125, 154;

    /*
    * gray-1500
    */
    --color-gray-1500: 250, 250, 250;

    /*
    * gray-1600
    */
    --color-gray-1600: 221, 221, 221;

    /*
    * gray-1700
    */
    --color-gray-1700: 239, 239, 241;

    /*
    * gray-1800
    */
    --color-gray-1800: 237, 237, 237;

    /*
    * gray-1900
    */
    --color-gray-1900:  209, 208, 217;

    /*
    * gray-2000
    */
    --color-gray-2000:  236, 236, 236;

    /*
    * green-1000
    */
    --color-green-1000: 106, 165, 67;

    /*
    * blue-1000
    */
    --color-blue-1000: 0, 122, 255;

    /*
    * blue-1100
    */
    --color-blue-1100: 204, 228, 255;

    /*
    * blue-1200
    */
    --color-blue-1200: 1, 123, 255;

    /*
    * blue-1300
    */
    --color-blue-1300: 36, 62, 133;

    /*
    * color-1000
    */
    --color-red-1000: 255, 59, 48;

    /*
    * color-1100
    */
    --color-red-1100: 255, 233, 232;

    /*
    * red-1200
    */
    --color-red-1200: 255, 29, 48;

    /*
    * red-1300
    */
    --color-red-1300: 255, 210, 214;

    /*
    * orange-20
    */
    --color-orange-20: 255, 251, 230;

    /*
    * orange-1000
    */
    --color-orange-1000: 255, 249, 240;

    /*
    * orange-1100
    */
    --color-orange-1100: 255, 168, 41;

    /*
    * purple-10
    */
    --color-purple-10: 13, 35, 118;

    /*
    * blue-100
    */
    --color-blue-100: 1, 123, 255;

    /*
    * green-100
    */
    --color-green-100: 46, 204, 112;
  }

  a {
    @apply no-underline;
  }
}

label {
  font-family: SVN-Proxima Nova;
  color: #17181c;
}

header {
  width: calc(100vw - 305px);
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./custom-plugins.css";
